<breadcrumb>
    <nav class="hidden sm:flex mb-5 mt-5" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
            <li>
                <div class="flex">
                    <a href="/" class="text-sm font-medium text-gray-500 hover:text-gray-700">Home</a>
                </div>
            </li>
            <slot />
        </ol>
    </nav>
</breadcrumb>