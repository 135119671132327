const _temp0 = require("../components/global/breadcrumb-item.riot");
const _temp1 = require("../components/global/breadcrumb.riot");
const _temp2 = require("../components/global/button-back.riot");
const _temp3 = require("../components/global/button-submit.riot");
const _temp4 = require("../components/global/chat.riot");
const _temp5 = require("../components/global/dashboard.riot");
const _temp6 = require("../components/global/disclaimer.riot");
const _temp7 = require("../components/global/edit-user.riot");
const _temp8 = require("../components/global/generic-table.riot");
const _temp9 = require("../components/global/heading-page.riot");
const _temp10 = require("../components/global/input-area.riot");
const _temp11 = require("../components/global/input-text.riot");
const _temp12 = require("../components/global/item-nav.riot");
const _temp13 = require("../components/global/login.riot");
const _temp14 = require("../components/global/logout-button.riot");
const _temp15 = require("../components/global/messaggio.riot");
const _temp16 = require("../components/global/modal-delete.riot");
const _temp17 = require("../components/global/modal-error.riot");
const _temp18 = require("../components/global/pagination.riot");
const _temp19 = require("../components/global/raw.riot");
const _temp20 = require("../components/global/table-td.riot");
const _temp21 = require("../components/global/table-th.riot");
const _temp22 = require("../components/global/transcript.riot");
const _temp23 = require("../components/global/users.riot");
module.exports = {
  "breadcrumb-item": _temp0,
  "breadcrumb": _temp1,
  "button-back": _temp2,
  "button-submit": _temp3,
  "chat": _temp4,
  "dashboard": _temp5,
  "disclaimer": _temp6,
  "edit-user": _temp7,
  "generic-table": _temp8,
  "heading-page": _temp9,
  "input-area": _temp10,
  "input-text": _temp11,
  "item-nav": _temp12,
  "login": _temp13,
  "logout-button": _temp14,
  "messaggio": _temp15,
  "modal-delete": _temp16,
  "modal-error": _temp17,
  "pagination": _temp18,
  "raw": _temp19,
  "table-td": _temp20,
  "table-th": _temp21,
  "transcript": _temp22,
  "users": _temp23
}