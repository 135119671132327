<users>
    <div class="py-8">
        <div>
            <heading-page ckey="{ ckey }" text="Gestione utenti" link="/{ ckey }/create" />
            <breadcrumb>
                <breadcrumb-item ckey="{ ckey }" link="" text="Anagrafica utenti" />
            </breadcrumb>
        </div>

        <generic-table ckey='{ ckey }' th_array='{ get_th_array() }' td_array='{ get_td_array() }' extra_buttons='{ get_extra_buttons() }' title_delete="{ getTitleModalDelete.bind(this) }" text_delete="{ getTextModalDelete.bind(this) }"></generic-table>
    </div>

    <script>
        import moment from 'moment-with-locales-es6'
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import * as constants from '/src/utils/constants'

        export default {

            ckey: constants.KEY_USERS,

            get_th_array() {
                const th_array = []
                th_array.push({
                    column: 'nome',
                    label: 'Nome',
                    sorting: true
                })
                th_array.push({
                    column: 'cognome',
                    label: 'Cognome',
                    sorting: true
                })
                th_array.push({
                    column: 'email',
                    label: 'Email',
                    sorting: true,
                    hidesm: true
                })
                th_array.push({
                    column: 'role',
                    label: 'Ruolo',
                    sorting: true,
                    hidesm: true
                })
                return th_array
            },

            get_td_array() {
                const td_array = []
                td_array.push({
                    field: 'nome',
                    highlight: false
                })
                td_array.push({
                    field: 'cognome',
                    highlight: true
                })
                td_array.push({
                    field: 'email',
                    highlight: false,
                    hidesm: true
                })
                td_array.push({
                    field: 'role',
                    highlight: false,
                    hidesm: true
                })
                return td_array
            },

            get_extra_buttons() {
                const extra_buttons = []
                extra_buttons.push({
                    action: 'report',
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" /></svg>',
                    label: 'Scarica conversazione'
                })
                return extra_buttons
            },

            onBeforeMount(props, state) {
                this.state = {
                    items: {
                        count: 0,
                        results: []
                    }
                }

                eventBus.on(constants.ITEMS_CHANGED, this.handleTableItemsChanged)
            },

            onBeforeUnmount(props, state) {
                eventBus.off(constants.ITEMS_CHANGED, this.handleTableItemsChanged)
            },

            handleTableItemsChanged(e) {
                if (e.ckey === this.ckey) {
                    this.update({
                        items: e.items
                    })
                }
            },

            getTitleModalDelete(e) {
                if (e.ckey === this.ckey) {
                    return "Eliminazione utente"
                }
            },

            getTextModalDelete(e) {
                if (e.ckey === this.ckey) {
                    const utente = this.state.items.results.find(item => item.id === e.item_id)
                    const nome = utente ? `${utente.get('nome')} ${utente.get('cognome')}` : ''
                    return "Stai per cancellare l'utente " + nome + ". L'azione è irreversibile. Vuoi continuare?"
                }
            }
        }
    </script>
</users>
