import Parse from './parse-init';
import moment from 'moment-with-locales-es6';
import eventBus from '/src/utils/event-bus';
import * as constants from '/src/utils/constants';

const Messaggio = Parse.Object.extend("Messaggio");

function _applySorting(query, sortColumn, sortDirection) {
    if ('asc' === sortDirection) {
        query.addAscending(sortColumn);
    } else if ('desc' === sortDirection) {
        query.addDescending(sortColumn);
    }
}

async function _getItems(Cl, defaultSortingField, sortColumn, sortDirection, page, search_text, include) {
    try {
        const query = new Parse.Query(Cl);
        query.notEqualTo('deleted', true);
        if (include) {
            query.include(include)
        }
        _applySorting(query, sortColumn || defaultSortingField, sortDirection)

        if (search_text) {
            query.equalTo('search_array', search_text)
        }
        let results = null;
        if (page > 0) {
            query.limit(constants.N_ITEMS)
            query.skip((page - 1) * constants.N_ITEMS)
            query.withCount()
            results = await query.find()
        } else {
            results = await _findAll(query)
        }
        return {items: results}
    } catch (e) {
        return {
            items: null,
            code: e.code,
            error: e.message
        }
    }
}

async function _getItem(Cl, item_id) {
    try {
        const query = new Parse.Query(Cl);
        const item = await query.get(item_id);
        return item;
    } catch (error) {
        console.error("Errore nel recupero dell'item:", error);
        return null;
    }
}

async function _deleteItem(Cl, item_id) {
    try {
        const item = Cl.createWithoutData(item_id);
        item.set("deleted", true);
        await item.save();
        return item_id;
    } catch (error) {
        console.error("Errore nel recupero dell'item:", error);
        return null;
    }
}

async function _findAll(query, dir, criteria) {
    let objects = [];
    let o = [];
    let n = 0;
    const limit = 1000;
    query.limit(limit);
    if (dir && dir < 0) {
        if (criteria) {
            query.descending(criteria);
        } else {
            query.descending("createdAt");
        }
    } else {
        if (criteria) {
            query.ascending(criteria);
        } else {
            query.ascending("createdAt");
        }
    }
    do {
        o = await query.find();
        objects = objects.concat(o);
        n++;
        query.skip(n * limit);
    } while (o.length === limit);
    return objects;
}

const StoreService = {

    async getItem(key, item_id) {
        let Cl = null
        let item = null
        switch (key) {
            case constants.KEY_USERS:
                Cl = Parse.User
                item = await _getItem(Cl, item_id)
                break
        }

        return item
    },

    async deleteItem(key, item_id) {
        let Cl = null
        switch (key) {
            case constants.KEY_USERS:
                const data = {
                    userId: item_id,
                };
                await Parse.Cloud.run('deleteUser', data);
                break
        }
        return item_id;
    },

    getUser() {
        let user = null;
        try {
            user = Parse.User.current();
        } catch (e) {
            console.error(e);
        }
        return user;
    },

    async getTranscript(user_id) {
        try {
            const messages = await Parse.Cloud.run("getTranscript", {
                user_id: user_id
            });
            return messages;
        } catch (error) {
            if (error.code === 209) {
                console.error("Token scaduto, effettuo il logout dell'utente.");
                await this.logout(); // Chiamata alla funzione logout in caso di token scaduto
            } else {
                console.error("Errore nel recupero della trascrizione:", error);
            }
        }
    },

    async login(username, password) {
        try {
            const user = await Parse.User.logIn(username, password, {usePost: false});
            eventBus.trigger(constants.LOGIN_SUCCESS, {
                user: user
            });
        } catch (e) {
            eventBus.trigger(constants.LOGIN_FAILED, {
                error: e
            });
        }
    },

    async logout() {
        try {
            await Parse.User.logOut();
            eventBus.trigger(constants.LOGOUT_SUCCESS);
        } catch (e) {
            // Cancella tutte le chiavi nel localStorage che iniziano con "Parse"
            for (let key in localStorage) {
                if (key.startsWith('Parse')) {
                    localStorage.removeItem(key);
                }
            }
            eventBus.trigger(constants.LOGOUT_FAILED, {
                error: e
            })
        }
    },

    async userAccept() {
        try {
            const d = moment().toISOString();
            const user = Parse.User.current();
            user.set("accettazione", d);
            await user.save();
            eventBus.trigger(constants.DISCLAIMER_ACCETTATO, {
                timestamp: d
            })
        } catch (error) {
            console.error("Errore nel salvataggio dell'item:", error);
        }
    },

    async getMessaggi() {
        try {
            const d = moment().format("YYYYMMDD");
            const user = Parse.User.current();
            const query = new Parse.Query(Messaggio);
            query.equalTo("utente", user);
            query.equalTo("giorno", d);
            query.limit(1000);
            query.ascending("createdAt");
            const messaggi = await query.find();
            eventBus.trigger(constants.LOADED_MESSAGES, {
                messaggi: messaggi
            })
        } catch (error) {
            if (error.code === 209) {
                console.error("Token scaduto, effettuo il logout dell'utente.");
                await this.logout(); // Chiamata alla funzione logout in caso di token scaduto
            } else {
                console.error("Errore nel recupero dei messaggi:", error);
            }
        }
    },

    async startChat() {
        try {
            const result = await Parse.Cloud.run("startChat");
            if (result.success) {
                eventBus.trigger(constants.NEW_MESSAGE, {
                    messaggio: result.messaggio
                });
            }
        } catch (error) {
            console.error("Errore nel salvataggio dell'item:", error);
        }
    },

    async sendMessaggio(testo) {
        try {
            let result = await Parse.Cloud.run("sendMessage", {
                message: testo
            });
            eventBus.trigger(constants.NEW_MESSAGE, {
                messaggio: result.messaggio
            });
            // Chiamata alla funzione Cloud Code per ottenere la risposta dell'AI
            result = await Parse.Cloud.run("getAIResponse");
            eventBus.trigger(constants.NEW_MESSAGE, {
                messaggio: result.messaggio
            });
        } catch (error) {
            if (error.code === 209) {
                console.error("Token scaduto, effettuo il logout dell'utente.");
                await this.logout(); // Chiamata alla funzione logout in caso di token scaduto
            } else {
                console.error("Errore nel salvataggio dell'item:", error);
            }
        }
    },

    async saveUtente(item_id, fname, lname, email, password) {
        const data = {
            userId: item_id,
            nome: fname,
            cognome: lname,
            email: email,
            password: password,
        }
        try {
            const utente = await Parse.Cloud.run('saveUser', data)
            return {item: utente}
        } catch (error) {
            if (error.code === 209) {
                console.error("Token scaduto, effettuo il logout dell'utente.");
                await this.logout(); // Chiamata alla funzione logout in caso di token scaduto
            } else {
                console.error("Errore nel recupero dei messaggi:", error);
                return {
                    item: null,
                    error: error.message
                }
            }
        }
    },

    async getItems(key, sortColumn, sortDirection, page, search_text, include) {
        let Cl = null
        let defaultField = null
        let data = []
        switch (key) {
            case constants.KEY_USERS:
                const params = {
                    sortColumn: sortColumn,
                    sortDirection: sortDirection,
                    page: page,
                    searchText: search_text,
                    nItems: constants.N_ITEMS
                };
                data = await Parse.Cloud.run('getUtenti', params);
                if (data) {
                    data = {items: data}
                }
                break
        }
        return data
    },

};

export default StoreService;
