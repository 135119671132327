<logout-button>
    <a class="text-blue-200 hover:text-white hover:bg-lime-500 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer" onclick="{
                logout
            }">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
        </svg>
        Logout
    </a>

    <script>
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import * as constants from '/src/utils/constants'

        export default {
            async logout() {
                await StoreService.logout()
            }
        }
    </script>
</logout-button>
