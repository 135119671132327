<transcript>
    <div class="py-8">
        <div>
            <heading-page ckey="{ ckey }" text="Trascrizione delle conversazioni" link="" />
            <breadcrumb>
                <breadcrumb-item ckey="{ ckey }" link="" text="Trascrizione delle conversazioni" />
            </breadcrumb>
        </div>
        <div class='mt-8'>
            <div each="{ day in state.groupedMessages }">
                <h2>{ formatDate(day.date) }</h2>
                <div each="{ msg in day.messages }">
                    <div if="{ msg.get('direzione') === -1 }">
                        <strong>Gloria</strong>: <em>{ changeAlias(msg.get('messaggio')) }</em>
                    </div>
                    <div if="{ msg.get('direzione') === 1 }">
                        <strong>Cliente</strong>: { changeAlias(msg.get('messaggio')) }
                    </div>
                </div>
            </div>
        </div>
    </div>

    <script>
        import moment from 'moment-with-locales-es6'
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import { router } from '@riotjs/route'
        import * as constants from '/src/utils/constants'

        export default {

            moment: moment,
            ckey: constants.KEY_TRANSCRIPT,

            onBeforeMount(props, state) {
                moment.locale('it')
                this.state = {
                    messages: [],
                    groupedMessages: [],
                    nome: '',
                    cognome: '',
                    alias: ''
                }
            },

            async onMounted(props, state) {
                const user = await StoreService.getItem(constants.KEY_USERS, props.item)
                this.update({
                    nome: user.get('nome'),
                    cognome: user.get('cognome'),
                    alias: user.get('alias') ? user.get('alias') : user.get('nome')
                })
                // Aggiorna la testata con il nome dell'atleta
                eventBus.trigger(constants.UPDATE_HEADING, {
                    ckey: this.ckey,
                    text: `Trascrizione delle conversazioni di ${user.get('nome')} ${user.get('cognome')}`
                })
                const messaggi = await StoreService.getTranscript(props.item)
                const groupedMessages = this.groupMessagesByDate(messaggi)
                this.update({
                    messages: messaggi,
                    groupedMessages: groupedMessages
                })
            },

            // Funzione per sostituire il nome con l'alias nel messaggio
            changeAlias(message) {
                const nome = this.state.nome;
                const alias = this.state.alias;

                // Sostituisci tutte le occorrenze del nome con l'alias
                if (nome && alias) {
                    return message.replace(new RegExp(nome, 'g'), alias);
                }
                return message; // Se il nome o l'alias non esistono, restituisce il messaggio originale
            },

            groupMessagesByDate(messages) {
                // Raggruppa i messaggi per giorno
                const grouped = {}

                messages.forEach(msg => {
                    const giorno = msg.get('giorno')
                    if (!grouped[giorno]) {
                        grouped[giorno] = []
                    }
                    grouped[giorno].push(msg)
                })

                // Converte l'oggetto in un array ordinato per data
                return Object.keys(grouped)
                        .sort() // Ordina le date in modo crescente
                        .map(date => ({
                                date: date,
                                messages: grouped[date]
                            }))
            },

            formatDate(dateStr) {
                const date = moment(dateStr, 'YYYYMMDD');
                // Ottiene la data formattata con la prima lettera in minuscolo
                const formattedDate = date.format('dddd D MMMM, YYYY');
                // Rende maiuscola la prima lettera della data formattata
                return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
            }


        }
    </script>
</transcript>
