<input-area>
    <div class="flex items-start space-x-4">
        <!--
        <div class="flex-shrink-0">
            <img class="inline-block h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
        </div>
        -->
        <div class="min-w-0 flex-1">
            <form method="POST" onsubmit="{
                        submit
                    }" class="relative">
                <div class="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-sky-700">
                    <label for="comment" class="sr-only">Scrivi qui...</label>
                    <textarea rows="3" name="comment" id="comment" class="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="Scrivi qui..." oninput="{ edit }" onkeydown="{ handleKeydown }"></textarea>

                    <!-- Spacer element to match the height of the toolbar -->
                    <div class="py-2" aria-hidden="true">
                        <!-- Matches height of button in toolbar (1px border + 36px content height) -->
                        <div class="py-px">
                            <div class="h-9"></div>
                        </div>
                    </div>
                </div>

                <div class="absolute inset-x-0 bottom-0 flex justify-end py-2 pl-3 pr-2">
                    <div class="flex-shrink-0">
                        <button type="submit" class="inline-flex items-center rounded-md bg-sky-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700">Invia</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <script>
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import * as constants from '/src/utils/constants'

        export default {

            ckey: constants.KEY_INPUT_AREA,

            onBeforeMount(props, state) {
                this.state = {
                    messaggio: ''
                }
            },

            onBeforeUnmount(props, state) {
            },

            edit(e) {
                this.update({
                    messaggio: e.target.value
                })
            },

            async submit(e) {
                e.preventDefault()
                const messaggio = this.state.messaggio.trim()
                if (messaggio) {
                    this.updateTextArea('')
                    this.update({
                        messaggio: ''
                    })
                    await StoreService.sendMessaggio(messaggio)
                }
            },

            handleKeydown(e) {
                if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();  // Impedisci il ritorno a capo
                    this.submit(e);  // Esegui la funzione di submit
                }
            },

            updateTextArea(value) {
                const textarea = this.root.querySelector('textarea');
                if (textarea) {
                    textarea.value = value;
                }
            }
        }

    </script>
</input-area>