<table-th>
    <div scope="col" class="{ props.first ? 'pl-4 pr-3 sm:pl-0 text-left' : (props.last ? 'pl-3 pr-4 sm:pr-0 text-right' : 'px-3 text-left') } py-3 text-xs font-medium uppercase tracking-wide text-gray-500 { props.hidemd ? 'hidden md:table-cell' : '' } { props.hidesm ? 'hidden sm:table-cell' : '' }">
        <a if="{ props.column }" class="group inline-flex" onclick="{
                    sortColumn
                }">
            <slot />
            <span if="{ props.sorting }" class="{ state.direction ? 'bg-gray-200 text-gray-900 group-hover:bg-gray-300' : 'invisible text-gray-400 group-hover:visible group-focus:visible' } ml-2 flex-none rounded">
                <svg if="{ state.direction === '' || state.direction === 'asc' }" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                </svg>
                <svg if="{ state.direction === 'desc' }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                    <path fill-rule="evenodd" d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z" clip-rule="evenodd" />
                </svg>
            </span>
        </a>
        <a if="{ !props.column }"></a>
    </div>

    <script>
        import eventBus from '/src/utils/event-bus'
        import * as events from '/src/utils/constants'

        export default {

            onBeforeMount(props, state) {
                this.state = {
                    direction: ''
                }

                eventBus.on(events.SORTING_CHANGE, this.handleSortingChange)
            },

            onBeforeUnmount(props, state) {
                eventBus.off(events.SORTING_CHANGE, this.handleSortingChange)
            },

            handleSortingChange(e) {
                if (e.ckey === props.ckey && e.column !== props.column) {
                    this.update({
                        direction: ''
                    })
                }
            },

            sortColumn() {
                this.update({
                    direction: (this.state.direction === 'asc' ? 'desc' : 'asc')
                })
                eventBus.trigger(events.SORTING_CHANGE, {
                    ckey: this.props.ckey,
                    column: this.props.column,
                    direction: this.state.direction
                })
            },
        }
    </script>
</table-th>
