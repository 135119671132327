<chat>
    <div class="flex flex-col justify-between h-screen py-8"> <!-- Usa h-screen per altezza 100% -->
        <div>
            <heading-page ckey="{ ckey }" text="Chat" link="" />
            <breadcrumb>
                <breadcrumb-item ckey="{ ckey }" link="" text="Chat" />
            </breadcrumb>
        </div>
        <div class="flex flex-col flex-grow overflow-hidden mt-8">
            <div id="messages_container" class="flex-grow overflow-auto px-2">
                <messaggio each="{ (m, index) in state.messaggi }" testo="{ m.get('messaggio') }" direzione="{ m.get('direzione') }" timestamp="{ m.createdAt }" last="{ (index == state.messaggi.length - 1) ? 1 : 0 }"></messaggio>
            </div>
        </div>
        <div class="pt-5 px-2">
            <input-area></input-area>
        </div>
    </div>

    <script>
        import moment from 'moment-with-locales-es6'
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import * as constants from '/src/utils/constants'

        export default {

            ckey: constants.KEY_CHAT,
            moment: moment,

            onBeforeMount(props, state) {
                moment.locale('it')
                this.state = {
                    messaggi: []
                }
                eventBus.on(constants.NEW_MESSAGE, this.newMessage)
                eventBus.on(constants.LOADED_MESSAGES, this.loadedMessages)
            },

            onBeforeUnmount() {
                eventBus.off(constants.NEW_MESSAGE, this.newMessage)
                eventBus.off(constants.LOADED_MESSAGES, this.loadedMessages)
            },

            async onMounted(props, state) {
                await StoreService.getMessaggi()
            },

            onUpdated() {
                this.scrollToBottom();
            },

            newMessage(result) {
                this.update({
                    messaggi: [...this.state.messaggi, result.messaggio]
                })
            },

            async loadedMessages(result) {
                if (result.messaggi.length === 0) {
                    await StoreService.startChat()
                } else {
                    this.update({
                        messaggi: result.messaggi
                    })
                }
            },

            scrollToBottom() {
                const container = this.$('#messages_container');
                container.scrollTop = container.scrollHeight;
            }
        }
    </script>
</chat>
