export const DELETE = 'delete';
export const DISCLAIMER_ACCETTATO = 'disclaimer_accettato';
export const GENERIC_ERROR = 'generic_error';
export const ITEMS_CHANGED = 'items_changed';
export const KEY_CHAT = 'chat';
export const KEY_DISCLAIMER = 'disclaimer';
export const KEY_INPUT_AREA = 'input_area';
export const KEY_USERS = 'users';
export const KEY_TRANSCRIPT = 'transcript';
export const LOADED_MESSAGES = 'loaded_messages';
export const LOADED_TRANSCRIPT = 'loaded_transcript';
export const LOGIN_FAILED = 'login_failed';
export const LOGIN_SUCCESS = 'login_success';
export const LOGOUT_FAILED = 'logout_failed';
export const LOGOUT_SUCCESS = 'logout_success';
export const N_ITEMS = 10;
export const NEW_MESSAGE = 'new_message';
export const OPEN_MODAL_DELETE = 'open_modal_delete';
export const OPEN_MODAL_ERROR = 'open_modal_error';
export const PAGINATION_CHANGE = 'pagination_change';
export const REFRESH = 'refresh';
export const ROLE_ADMIN = 'role_admin';
export const ROLE_USER = 'role_user';
export const SAVE = 'save';
export const SEARCH = 'search';
export const SORTING_CHANGE = 'sorting_change';
export const UPDATE_BREADCRUMB = 'update_breadcrumb';
export const UPDATE_HEADING = 'update_heading';
export const UPDATE_NAV = 'update_nav';