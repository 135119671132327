<pagination>
    <div class="border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
        <div class="flex flex-1 justify-between sm:hidden">
            <span class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50" onclick="{
                                previous
                            }">Precedente</span>
            <span class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50" onclick="{
                                next
                            }">Successiva</span>
        </div>
        <p class="sm:hidden text-sm text-gray-700 my-3">Pagina { state.page } di { state.total_pages }</p>
        <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
                <p class="text-sm text-gray-700">
                    Visualizza da
                    <span class="font-medium">{ (state.items_per_page * (state.page - 1) + 1) }</span>
                    a
                    <span class="font-medium">{ ((state.items_per_page * state.page) < state.n_items ? (state.items_per_page * state.page) : state.n_items) }</span>
                    di
                    <span class="font-medium">{ state.n_items }</span>
                    risultati
                </p>
            </div>
            <div>
                <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                    <span class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onclick="{
                                previous
                            }">
                        <span class="sr-only">Precedente</span>
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                        </svg>
                    </span>
                    <span each="{ i in state.pages_to_show }" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold { state.page === i ? 'z-10 bg-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600' : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0' }" onclick="{
                                changePage.bind(this, i)
                            }">{ i }</span>
                    <span class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onclick="{
                                next
                            }">
                        <span class="sr-only">Successiva</span>
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                        </svg>
                    </span>
                </nav>
            </div>
        </div>
    </div>

    <script>
        import eventBus from '/src/utils/event-bus'
        import * as constants from '/src/utils/constants'

        export default {

            onBeforeMount(props, state) {
                this.state = {
                    page: 1,
                    items_per_page: constants.N_ITEMS,
                    n_items: 0,
                    total_pages: 0,
                    pages_to_show: [1]
                }

                eventBus.on(constants.ITEMS_CHANGED, this.handlePaginationItemsChanged)
            },

            previous() {
                if (this.state.page > 1) {
                    this.changePage(this.state.page - 1)
                }
            },

            next() {
                if (this.state.page < this.state.total_pages) {
                    this.changePage(this.state.page + 1)
                }
            },

            changePage(p) {
                const totalPages = Math.ceil(this.state.n_items / this.state.items_per_page)
                const pagesToShow = this.evalPagesToShow(totalPages, p);

                this.update({
                    page: p,
                    pages_to_show: pagesToShow
                })
                eventBus.trigger(constants.PAGINATION_CHANGE, {
                    ckey: this.props.ckey,
                    page: this.state.page
                })
            },

            onBeforeUnmount(props, state) {
                eventBus.off(constants.ITEMS_CHANGED, this.handlePaginationItemsChanged)
            },

            evalPagesToShow(totalPages, currentPage) {
                let pagesToShow = [];

                // Aggiungi sempre la prima pagina
                pagesToShow.push(1);

                // Aggiungi sempre l'ultima pagina, se ci sono più di una pagina
                if (totalPages > 1) {
                    if (currentPage !== 1 && currentPage !== totalPages) {
                        // la pagina corrente va mostrata
                        // determino se visualizzare o meno puntini
                        if (currentPage > 2) {
                            pagesToShow.push('...');
                        }
                        pagesToShow.push(currentPage);
                        if (currentPage < totalPages - 1) {
                            pagesToShow.push('...');
                        }
                    } else if (totalPages > 2) {
                        pagesToShow.push('...');
                    }
                    pagesToShow.push(totalPages);
                }

                return pagesToShow;
            },

            handlePaginationItemsChanged(e) {
                if (e.ckey === this.props.ckey) {
                    if (this.state.n_items !== e.items.count || this.state.page !== e.page) {
                        const totalPages = Math.ceil(e.items.count / this.state.items_per_page)
                        const pagesToShow = this.evalPagesToShow(totalPages, 1);
                        this.update({
                            page: 1,
                            n_items: e.items.count,
                            total_pages: totalPages,
                            pages_to_show: pagesToShow
                        })
                    }
                }
            }
        }
    </script>
</pagination>
