<modal-delete>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div if="{ state.modal_visible }" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ease-out duration-300 { state.modal_visible ? 'opacity-100' : 'opacity-0' }"></div>
        <div if="{ state.modal_visible }" class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 { state.modal_visible ? 'opacity-100 translate-y-0 sm:scale-100' : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' }">
                    <div class="sm:flex sm:items-start">
                        <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                            </svg>
                        </div>
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">{ state.title }</h3>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500"><raw html="{ state.text }" /></p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button type="button" onclick="{
                                    delete_item
                                }" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Elimina</button>
                        <button type="button" onclick="{
                                    close_modal
                                }" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Annulla</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <script>
        import eventBus from '/src/utils/event-bus'
        import * as constants from '/src/utils/constants'

        export default {

            onBeforeMount(props, state) {
                this.state = {
                    title: '',
                    text: '',
                    item_id: '',
                    modal_visible: false
                }

                eventBus.on(constants.OPEN_MODAL_DELETE, this.open_modal)
            },

            onBeforeUnmount(props, state) {
                eventBus.off(constants.OPEN_MODAL_DELETE, this.open_modal)
            },

            delete_item() {
                eventBus.trigger(constants.DELETE, {
                    ckey: this.props.ckey,
                    item_id: this.state.item_id
                })
                this.close_modal()
            },

            close_modal() {
                this.update({
                    title: '',
                    text: '',
                    item_id: '',
                    modal_visible: false
                })
            },

            open_modal(e) {
                if (e.ckey === this.props.ckey) {
                    this.update({
                        title: e.title,
                        text: e.text,
                        item_id: e.item_id,
                        modal_visible: true
                    })
                }
            }
        }
    </script>
</modal-delete>
