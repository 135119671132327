<disclaimer>
    <div class="py-8">
        <div>
            <heading-page ckey="{ ckey }" text="Disclaimer" link="" />
            <breadcrumb>
                <breadcrumb-item ckey="{ ckey }" link="" text="Disclaimer" />
            </breadcrumb>
        </div>
        <div class='mt-8'>
            <p>
                L'uso del servizio di counseling AI è fornito a scopo informativo ed educativo. Il servizio non è destinato a sostituire il consiglio, la diagnosi o il trattamento professionale di un consulente, psicologo, medico o altro professionista della salute mentale qualificato. Le informazioni e i consigli forniti dall'IA possono non essere accurati, completi o applicabili alle circostanze individuali.
            </p>
            <h3 class="my-5">Conservazione delle Conversazioni</h3>
            <p>
                Tutte le conversazioni saranno salvate in forma anonima per consentire il miglioramento e il fine tuning dell'IA. Utilizzando il servizio, l'utente accetta che i suoi dati possano essere utilizzati a questo scopo.
            </p>
            <h3 class="my-5">Uso a Proprio Rischio</h3>
            <ol class="list-decimal list-inside">
                <li>
                    <strong>Accettazione del Rischio:</strong> Utilizzando questo servizio, l'utente riconosce e accetta che l'uso del servizio è a proprio rischio e pericolo. Non garantiamo l'accuratezza, l'affidabilità o la completezza delle risposte fornite dall'IA.
                </li>
                <li>
                    <strong>Nessuna Responsabilità:</strong> 2caffe srl, i suoi affiliati, dipendenti e fornitori di servizi non saranno responsabili per eventuali danni, diretti o indiretti, derivanti dall'uso del servizio. Ciò include, ma non è limitato a, danni causati da errori, omissioni, interruzioni, difetti, ritardi nel funzionamento o nella trasmissione, virus informatici, o guasti della linea.
                </li>
                <li>
                    <strong>Non un Sostituto di Consulenza Professionale:</strong> Le informazioni e i consigli forniti dal servizio di counseling AI non devono essere interpretati come un sostituto della consulenza professionale. Se avete bisogno di assistenza professionale, vi consigliamo di consultare un consulente qualificato, uno psicologo o un altro professionista della salute mentale. Puoi trovare un elenco di Counselor professionisti qui: <a href="https://www.miocounselor.net">miocounselor.net</a>
                </li>
                <li>
                    <strong>Confidenzialità Limitata:</strong> Nonostante gli sforzi per mantenere la riservatezza e la sicurezza delle informazioni, Quando trasmettiamo informazioni via Internet, utilizziamo tecnologie di sicurezza avanzate per proteggerle, come la crittografia e altre misure di protezione. Tuttavia, nessun sistema online è immune al 100% da potenziali minacce, come attacchi informatici o intercettazioni non autorizzate. Questo è quello che chiamiamo 'rischio residuo'. Significa che, anche se facciamo il massimo per proteggere i tuoi dati, c'è sempre una piccola possibilità che qualcosa possa andare storto, come ad esempio un tentativo di accesso non autorizzato. Tuttavia, ci impegniamo a monitorare costantemente la sicurezza del nostro sistema e a rispondere rapidamente in caso di problemi. 
                </li>
                <li>
                    <strong>Accettazione dei Termini:</strong> Continuando ad utilizzare questo servizio, l'utente accetta questi termini e condizioni. Se non si è d'accordo con questi termini, si prega di non utilizzare il servizio.
                </li>
            </ol>
            <p class="mt-5">
                <strong>Nota Importante:</strong> Se state attraversando una crisi o avete bisogno di assistenza immediata, vi invitiamo a contattare un professionista della salute mentale qualificato o i servizi di emergenza locali.
            </p>
        </div>
        <button class="mt-5 rounded-md bg-sky-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700" onclick="{
                    accetta
                }" disabled="{ state.accettato ? true : false }">Accetta</button>
        <!-- aggiungere bottone per andare a chat -->
        <p if="{ state.accettato ? true : false }" class="mt-3">Il Disclaimer è stato accettato il giorno { moment(state.accettato).format('dddd D MMMM YYYY') } alle ore { moment(state.accettato).format('HH:mm') }.<br />
            Per revocare questo consenso e cancellare tutti i dati connessi al tuo utente, scrivi a <a href="mailto:marco.celesti@2caffe.it">marco.celesti@2caffe.it</a></p>
    </div>

    <script>
        import moment from 'moment-with-locales-es6'
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import * as constants from '/src/utils/constants'

        export default {

            ckey: constants.KEY_DISCLAIMER,
            moment: moment,

            onBeforeMount(props, state) {
                moment.locale('it')
                this.state = {
                    accettato: StoreService.getUser().get('accettazione')
                }

                eventBus.on(constants.DISCLAIMER_ACCETTATO, this.disclaimerAccettato)
            },

            onBeforeUnount(props, state) {
                eventBus.off(constants.DISCLAIMER_ACCETTATO, this.disclaimerAccettato)
            },

            async accetta() {
                await StoreService.userAccept()
            },

            disclaimerAccettato(result) {
                this.update({
                    accettato: result.timestamp
                })
            }
        }
    </script>
</disclaimer>
