<app>
    <router>
        <div if={state.user}>
            <dashboard></dashboard>
        </div>
        <div if={!state.user}>
            <login></login>
        </div>
    </router>

    <script>
        import { setBase, router } from '@riotjs/route'
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import Parse from '/src/services/parse-init'
        import * as constants from '/src/utils/constants';

        setBase('/')

        export default {

            onBeforeMount() {
                this.state = {
                    user: StoreService.getUser(),
                    accettato: (StoreService.getUser() && StoreService.getUser().get("accettazione")) ? true : false
                }

                eventBus.on(constants.LOGIN_SUCCESS, this.loginSuccess)
                eventBus.on(constants.LOGOUT_SUCCESS, this.logoutDone)
                eventBus.on(constants.LOGOUT_FAILED, this.logoutDone)
            },

            redirectToDisclaimer() {
                const path = '/' + constants.KEY_DISCLAIMER
                history.pushState({}, '', path)
                router.push(path)
            },

            loginSuccess(result) {
                this.update({user: result.user})
                const path = '/'
                history.pushState({}, '', path)
                router.push(path)
            },

            logoutDone() {
                this.update({user: null})
            },

            onBeforeUnmount() {
                eventBus.off(constants.LOGIN_SUCCESS, this.loginSuccess)
                eventBus.off(constants.LOGOUT_SUCCESS, this.logoutDone)
                eventBus.off(constants.LOGOUT_FAILED, this.logoutDone)
            }
        };
    </script>
</app>
